import React from "react";
import SEO from "../components/global/Seo";
import Layout from "../components/layout/Layout";

import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import Masonry from "react-masonry-css";

export default () => {
  const query = useStaticQuery(graphql`
    query {
      bannerImg: file(relativePath: { eq: "kawa.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      bannerImg2: file(relativePath: { eq: "kawa2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      musetti: file(relativePath: { eq: "producenci/musetti.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      mokabar: file(relativePath: { eq: "producenci/mokabar.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      guzzini: file(relativePath: { eq: "producenci/guzzini.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      klienci: allFile(
        sort: { fields: [base] }
        filter: { relativeDirectory: { eq: "klienci" } }
      ) {
        edges {
          node {
            childImageSharp {
              fluid(maxWidth: 200) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  `);

  return (
    <Layout pageTitle="Kawy">
      <SEO title="Kawy" />
      <div className="row align-items-center section-padding-xs paragraph-content">
        <div className="col-md-6">
          <div className="text-box-padding-xs-right">
            <h2>Twój dostawca kawy</h2>
            <strong>
              Kawa to nasza pasja i z chęcią podzielimy się nią z Twoją firmą.
            </strong>
            <p>
              Kilka miesięcy trwało dobieranie najlepszych mieszanek do naszej
              oferty. Jesteśmy w stanie sprostać różnym upodobaniom! Kawa
              wyrazista, mocna, aromatyczna, czy może delikatna i kremowa? Opisy
              Ci nie wystarczają? Umów się na degustację lub zamów kilogram na
              próbę.
            </p>
            <p>
              Skontaktuj się z nami, nasi konsultanci nie mogą się doczekać
              nowych wyzwań związanych z kawą.
            </p>
          </div>
        </div>
        <div className="col-md-6">
          <Img fluid={query.bannerImg.childImageSharp.fluid} alt="Banner" />
        </div>
      </div>
      <div className="row paragraph-content producer-info">
        <div className="col-md-3">
          <h3 className="producer-title">Kawa Musetti</h3>
          <Img
            className="producer-logo"
            fluid={query.musetti.childImageSharp.fluid}
            alt="Musetti"
          />
        </div>
        <div className="col-md-8 offset-md-1">
          <p>
            Palarnia Musetti istnieje nie przerwanie na rynku od ponad 80 lat, w
            sześćdziesięciu krajach na pięciu kontynentach. Mieszanki kawy są
            rygorystycznie selekcjonowane dzięki czemu zapewniają niezwykle
            intensywną gamę smaków oraz intensywny aromat. Wśród szerokiej
            palety kaw znajdują się mieszanki takie jak Cremissimo i GoldCuvee,
            które co roku zdobywają liczne nagrody w konkursach na całym
            świecie. Poszczycić możemy się również kawą Musetti Midori BIO, bez
            pestycydów, nawozów chemicznych, deforestacji.
          </p>
        </div>
      </div>
      <div className="row paragraph-content producer-info">
        <div className="col-md-3">
          <h3 className="producer-title">Kawa Mokabar</h3>
          <Img
            className="producer-logo"
            fluid={query.mokabar.childImageSharp.fluid}
            alt="Mokabar"
          />
        </div>
        <div className="col-md-8 offset-md-1">
          <p>
            Tradycja palenia kawy od 1961 - Palarnia Mokabar jest rodzinną,
            rzemieślniczą firmą z Turynu, dla której od zawsze priorytetami jest
            najwyższa jakość produktu, stała kontrola surowców oraz
            profesjonalna obsługa oferowana klientom. Jesteśmy bezpośrednim i
            jedynym importerem kaw Mokabar. Dzięki regularnym dostawom nasze
            mieszanki są zawsze świeże. Kawa zachowuje swój znakomity smak i
            intensywny aromat, do ostatniego ziarnka.
          </p>
        </div>
      </div>
      <div className="row paragraph-content producer-info">
        <div className="col-md-3">
          <h3 className="producer-title">Kawa Guzzini</h3>
          <Img
            className="producer-logo"
            fluid={query.guzzini.childImageSharp.fluid}
            alt="Guzzini"
          />
        </div>
        <div className="col-md-8 offset-md-1">
          <p>
            Kawy marki Guzzini to nasze autorskie mieszanki wypalane w palarni
            na południu kraju specjalnie na nasze życzenie. Nie od dziś wiadomo
            iż najważniejszym elementem pysznej kawy jest świeże palenie. Trzy
            mieszanki o różnym składzie oraz stopniu palenia gwarantują
            zróżnicowanie smaku i intensywności aromatu, dzięki czemu mogą
            sprostać odmiennym upodobaniom naszych klientów.
          </p>
        </div>
      </div>
      <div className="row align-items-center justify-content-end section-padding-xs image-text-box paragraph-content">
        <div className="box-image">
          <Img fluid={query.bannerImg2.childImageSharp.fluid} alt="Banner" />
        </div>
        <div className="col-md-8 col-lg-6">
          <div className="box-text">
            <h3>Rekomendują nas między innymi</h3>

            <Masonry
              breakpointCols={4}
              className="masonry-grid mt-4"
              columnClassName="masonry-grid_column"
            >
              {query.klienci.edges.map((edge, i) => {
                return (
                  <div key={i}>
                    <Img
                      key={i}
                      fluid={edge.node.childImageSharp.fluid}
                      alt="Klient logo"
                    />
                  </div>
                );
              })}
            </Masonry>
          </div>
        </div>
      </div>
    </Layout>
  );
};
